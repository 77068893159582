// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-admin-rebuild-syddurwgud-jsx": () => import("./../../../src/pages/admin/rebuild-syddurwgud.jsx" /* webpackChunkName: "component---src-pages-admin-rebuild-syddurwgud-jsx" */),
  "component---src-pages-comments-jsx": () => import("./../../../src/pages/comments.jsx" /* webpackChunkName: "component---src-pages-comments-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-list-jsx": () => import("./../../../src/pages/list.jsx" /* webpackChunkName: "component---src-pages-list-jsx" */),
  "component---src-pages-submit-jsx": () => import("./../../../src/pages/submit.jsx" /* webpackChunkName: "component---src-pages-submit-jsx" */),
  "component---src-pages-submit-profile-jsx": () => import("./../../../src/pages/submit-profile.jsx" /* webpackChunkName: "component---src-pages-submit-profile-jsx" */),
  "component---src-templates-author-jsx": () => import("./../../../src/templates/author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

